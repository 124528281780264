// extracted by mini-css-extract-plugin
export default {"root":"AppSideNavGlobal__root--r8D4Tl5","isResizing":"AppSideNavGlobal__isResizing--aGBhs1C","rootResizeHandler":"AppSideNavGlobal__rootResizeHandler--ZE7Rt_k","isCollapsed":"AppSideNavGlobal__isCollapsed--LlupHaw","isAdmin":"AppSideNavGlobal__isAdmin--BtM3PmL","versionListName":"AppSideNavGlobal__versionListName--hx4ggsb","label":"AppSideNavGlobal__label--tkMYEms","firstMenu":"AppSideNavGlobal__firstMenu--_l7iXod","content":"AppSideNavGlobal__content--takaatX","isAdminContent":"AppSideNavGlobal__isAdminContent--o9M07zU","textWithTooltip":"AppSideNavGlobal__textWithTooltip--QZnjuza","title":"AppSideNavGlobal__title--TZ5DR1V","rightIcon":"AppSideNavGlobal__rightIcon--bg3W0hc","icons":"AppSideNavGlobal__icons--xcqOwzB","logo":"AppSideNavGlobal__logo--SB8GyPt","chevronDown":"AppSideNavGlobal__chevronDown--lNB85io","goToDefaulOrg":"AppSideNavGlobal__goToDefaulOrg--P39BHv_","innerRootMenu":"AppSideNavGlobal__innerRootMenu--pz_Zn4s","secondRoot":"AppSideNavGlobal__secondRoot--RoPx9CL","secondMenu":"AppSideNavGlobal__secondMenu--rZzOMQH","thirdMenu":"AppSideNavGlobal__thirdMenu--QsI6lY2","versionAddButton":"AppSideNavGlobal__versionAddButton--Vrn4xPt","versionList":"AppSideNavGlobal__versionList--Ku_QRJo","thirdRoot":"AppSideNavGlobal__thirdRoot--mn0iTxu","chevron":"AppSideNavGlobal__chevron--XRUkWYW","hoverItem":"AppSideNavGlobal__hoverItem--FEb8Z9j"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"root\":\"AppSideNavGlobal__root--r8D4Tl5\",\"isResizing\":\"AppSideNavGlobal__isResizing--aGBhs1C\",\"rootResizeHandler\":\"AppSideNavGlobal__rootResizeHandler--ZE7Rt_k\",\"isCollapsed\":\"AppSideNavGlobal__isCollapsed--LlupHaw\",\"isAdmin\":\"AppSideNavGlobal__isAdmin--BtM3PmL\",\"versionListName\":\"AppSideNavGlobal__versionListName--hx4ggsb\",\"label\":\"AppSideNavGlobal__label--tkMYEms\",\"firstMenu\":\"AppSideNavGlobal__firstMenu--_l7iXod\",\"content\":\"AppSideNavGlobal__content--takaatX\",\"isAdminContent\":\"AppSideNavGlobal__isAdminContent--o9M07zU\",\"textWithTooltip\":\"AppSideNavGlobal__textWithTooltip--QZnjuza\",\"title\":\"AppSideNavGlobal__title--TZ5DR1V\",\"rightIcon\":\"AppSideNavGlobal__rightIcon--bg3W0hc\",\"icons\":\"AppSideNavGlobal__icons--xcqOwzB\",\"logo\":\"AppSideNavGlobal__logo--SB8GyPt\",\"chevronDown\":\"AppSideNavGlobal__chevronDown--lNB85io\",\"goToDefaulOrg\":\"AppSideNavGlobal__goToDefaulOrg--P39BHv_\",\"innerRootMenu\":\"AppSideNavGlobal__innerRootMenu--pz_Zn4s\",\"secondRoot\":\"AppSideNavGlobal__secondRoot--RoPx9CL\",\"secondMenu\":\"AppSideNavGlobal__secondMenu--rZzOMQH\",\"thirdMenu\":\"AppSideNavGlobal__thirdMenu--QsI6lY2\",\"versionAddButton\":\"AppSideNavGlobal__versionAddButton--Vrn4xPt\",\"versionList\":\"AppSideNavGlobal__versionList--Ku_QRJo\",\"thirdRoot\":\"AppSideNavGlobal__thirdRoot--mn0iTxu\",\"chevron\":\"AppSideNavGlobal__chevron--XRUkWYW\",\"hoverItem\":\"AppSideNavGlobal__hoverItem--FEb8Z9j\"}";
        // 1726802467592
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  