// extracted by mini-css-extract-plugin
export default {"readContainer":"helpers__readContainer--IcWSH1n","text":"helpers__text--sMMhT_O","divider":"helpers__divider--gE93eSJ","flexContainer":"helpers__flexContainer--bU6UObm"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"readContainer\":\"helpers__readContainer--IcWSH1n\",\"text\":\"helpers__text--sMMhT_O\",\"divider\":\"helpers__divider--gE93eSJ\",\"flexContainer\":\"helpers__flexContainer--bU6UObm\"}";
        // 1723129362119
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  