// extracted by mini-css-extract-plugin
export default {"divider":"helpers__divider--wCkmtLv","menuNode":"helpers__menuNode--GeL0zur","children":"helpers__children--BcUi69u","text":"helpers__text--UvKJSYS","isAdminMode":"helpers__isAdminMode--yuCfjPh","nestedElement":"helpers__nestedElement--WdD9PKt","optimizeSummaryContainer":"helpers__optimizeSummaryContainer--uhq7xpj","top":"helpers__top--vSmXHSH","versionId":"helpers__versionId--hKpoP6G","weight":"helpers__weight--I4oJ3F8","noSummary":"helpers__noSummary--zO8tN_v","summaryItemNode":"helpers__summaryItemNode--PNMhe9r","parentItem":"helpers__parentItem--J7JZvAB","nameAndIcon":"helpers__nameAndIcon--l2ej5iH","name":"helpers__name--nuS4XIr","icon":"helpers__icon--Up6vTtJ","score":"helpers__score--H0kcHOY","valueAndUnit":"helpers__valueAndUnit--YCjXXbB","value":"helpers__value--Dlgma_Z","unit":"helpers__unit--IAAqz0w","diff":"helpers__diff--ACfzS6v","childItems":"helpers__childItems--zX_PA8T","rootItems":"helpers__rootItems--xRl1pkq","referencedSheetDisplayName":"helpers__referencedSheetDisplayName--O6GQqE6"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"divider\":\"helpers__divider--wCkmtLv\",\"menuNode\":\"helpers__menuNode--GeL0zur\",\"children\":\"helpers__children--BcUi69u\",\"text\":\"helpers__text--UvKJSYS\",\"isAdminMode\":\"helpers__isAdminMode--yuCfjPh\",\"nestedElement\":\"helpers__nestedElement--WdD9PKt\",\"optimizeSummaryContainer\":\"helpers__optimizeSummaryContainer--uhq7xpj\",\"top\":\"helpers__top--vSmXHSH\",\"versionId\":\"helpers__versionId--hKpoP6G\",\"weight\":\"helpers__weight--I4oJ3F8\",\"noSummary\":\"helpers__noSummary--zO8tN_v\",\"summaryItemNode\":\"helpers__summaryItemNode--PNMhe9r\",\"parentItem\":\"helpers__parentItem--J7JZvAB\",\"nameAndIcon\":\"helpers__nameAndIcon--l2ej5iH\",\"name\":\"helpers__name--nuS4XIr\",\"icon\":\"helpers__icon--Up6vTtJ\",\"score\":\"helpers__score--H0kcHOY\",\"valueAndUnit\":\"helpers__valueAndUnit--YCjXXbB\",\"value\":\"helpers__value--Dlgma_Z\",\"unit\":\"helpers__unit--IAAqz0w\",\"diff\":\"helpers__diff--ACfzS6v\",\"childItems\":\"helpers__childItems--zX_PA8T\",\"rootItems\":\"helpers__rootItems--xRl1pkq\",\"referencedSheetDisplayName\":\"helpers__referencedSheetDisplayName--O6GQqE6\"}";
        // 1723129361204
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  