// extracted by mini-css-extract-plugin
export default {"container":"ResendEmailVerification__container--zP9volr","contentBox":"ResendEmailVerification__contentBox--BrhXs2p","logo":"ResendEmailVerification__logo--nagtsGC","message":"ResendEmailVerification__message--iEMoIiv","buttons":"ResendEmailVerification__buttons--yRFNLE6","button":"ResendEmailVerification__button--Aom9uBF","messageWrapper":"ResendEmailVerification__messageWrapper--j8kAeba"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"ResendEmailVerification__container--zP9volr\",\"contentBox\":\"ResendEmailVerification__contentBox--BrhXs2p\",\"logo\":\"ResendEmailVerification__logo--nagtsGC\",\"message\":\"ResendEmailVerification__message--iEMoIiv\",\"buttons\":\"ResendEmailVerification__buttons--yRFNLE6\",\"button\":\"ResendEmailVerification__button--Aom9uBF\",\"messageWrapper\":\"ResendEmailVerification__messageWrapper--j8kAeba\"}";
        // 1723129360217
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  