// extracted by mini-css-extract-plugin
export default {"inputRow":"DialogVersionDuplicateSome__inputRow--rlg73ix","versionTitleInput":"DialogVersionDuplicateSome__versionTitleInput--i4I2HC0"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"inputRow\":\"DialogVersionDuplicateSome__inputRow--rlg73ix\",\"versionTitleInput\":\"DialogVersionDuplicateSome__versionTitleInput--i4I2HC0\"}";
        // 1723129362741
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  