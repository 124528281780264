// extracted by mini-css-extract-plugin
export default {"closeOpenMenu":"AppSideNavGlobalVersions__closeOpenMenu--vWEE5F8","optimizeSummaryContainer":"AppSideNavGlobalVersions__optimizeSummaryContainer--hse0w_C","top":"AppSideNavGlobalVersions__top--PaDAs9I","versionId":"AppSideNavGlobalVersions__versionId--hxqoyBc","weight":"AppSideNavGlobalVersions__weight--v4r4Mf4","summaryItemNode":"AppSideNavGlobalVersions__summaryItemNode--fDHXP5k","parentItem":"AppSideNavGlobalVersions__parentItem--yVRSe21","nameAndIcon":"AppSideNavGlobalVersions__nameAndIcon--uFzoIxM","name":"AppSideNavGlobalVersions__name--PJ7G63e","icon":"AppSideNavGlobalVersions__icon--_qZkW5c","score":"AppSideNavGlobalVersions__score--QFLqh3P","valueAndUnit":"AppSideNavGlobalVersions__valueAndUnit--CE70erP","value":"AppSideNavGlobalVersions__value--m1HEJIH","unit":"AppSideNavGlobalVersions__unit--UHuR5y4","diff":"AppSideNavGlobalVersions__diff--r0pYGne","childItems":"AppSideNavGlobalVersions__childItems--71HFIko","rootItems":"AppSideNavGlobalVersions__rootItems--e3bT6LB","showAllVersionsButton":"AppSideNavGlobalVersions__showAllVersionsButton--u6K4XsL"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"closeOpenMenu\":\"AppSideNavGlobalVersions__closeOpenMenu--vWEE5F8\",\"optimizeSummaryContainer\":\"AppSideNavGlobalVersions__optimizeSummaryContainer--hse0w_C\",\"top\":\"AppSideNavGlobalVersions__top--PaDAs9I\",\"versionId\":\"AppSideNavGlobalVersions__versionId--hxqoyBc\",\"weight\":\"AppSideNavGlobalVersions__weight--v4r4Mf4\",\"summaryItemNode\":\"AppSideNavGlobalVersions__summaryItemNode--fDHXP5k\",\"parentItem\":\"AppSideNavGlobalVersions__parentItem--yVRSe21\",\"nameAndIcon\":\"AppSideNavGlobalVersions__nameAndIcon--uFzoIxM\",\"name\":\"AppSideNavGlobalVersions__name--PJ7G63e\",\"icon\":\"AppSideNavGlobalVersions__icon--_qZkW5c\",\"score\":\"AppSideNavGlobalVersions__score--QFLqh3P\",\"valueAndUnit\":\"AppSideNavGlobalVersions__valueAndUnit--CE70erP\",\"value\":\"AppSideNavGlobalVersions__value--m1HEJIH\",\"unit\":\"AppSideNavGlobalVersions__unit--UHuR5y4\",\"diff\":\"AppSideNavGlobalVersions__diff--r0pYGne\",\"childItems\":\"AppSideNavGlobalVersions__childItems--71HFIko\",\"rootItems\":\"AppSideNavGlobalVersions__rootItems--e3bT6LB\",\"showAllVersionsButton\":\"AppSideNavGlobalVersions__showAllVersionsButton--u6K4XsL\"}";
        // 1723129361264
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  