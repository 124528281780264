// extracted by mini-css-extract-plugin
export default {"wrapper":"DragAndDropFields__wrapper--tTP1DMD","header":"DragAndDropFields__header--WzJis56","leftEndContent":"DragAndDropFields__leftEndContent--lZeB2Kh","rightEndContent":"DragAndDropFields__rightEndContent--ufjeCCI","body":"DragAndDropFields__body--bLHu2kd","message":"DragAndDropFields__message--BagwkO_","dragAndDrop":"DragAndDropFields__dragAndDrop--MwsTddx","file":"DragAndDropFields__file--ae1PfBB","drag":"DragAndDropFields__drag--WFj0N6V","disable":"DragAndDropFields__disable--mJy0ruT","uploadedFileList":"DragAndDropFields__uploadedFileList--NoB1Um5","uploadedData":"DragAndDropFields__uploadedData--XxPrsJW","leftContent":"DragAndDropFields__leftContent--YBf_WGs","info":"DragAndDropFields__info--NnI_fuL","name":"DragAndDropFields__name--PdsBcpf"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"wrapper\":\"DragAndDropFields__wrapper--tTP1DMD\",\"header\":\"DragAndDropFields__header--WzJis56\",\"leftEndContent\":\"DragAndDropFields__leftEndContent--lZeB2Kh\",\"rightEndContent\":\"DragAndDropFields__rightEndContent--ufjeCCI\",\"body\":\"DragAndDropFields__body--bLHu2kd\",\"message\":\"DragAndDropFields__message--BagwkO_\",\"dragAndDrop\":\"DragAndDropFields__dragAndDrop--MwsTddx\",\"file\":\"DragAndDropFields__file--ae1PfBB\",\"drag\":\"DragAndDropFields__drag--WFj0N6V\",\"disable\":\"DragAndDropFields__disable--mJy0ruT\",\"uploadedFileList\":\"DragAndDropFields__uploadedFileList--NoB1Um5\",\"uploadedData\":\"DragAndDropFields__uploadedData--XxPrsJW\",\"leftContent\":\"DragAndDropFields__leftContent--YBf_WGs\",\"info\":\"DragAndDropFields__info--NnI_fuL\",\"name\":\"DragAndDropFields__name--PdsBcpf\"}";
        // 1723129362051
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  