// extracted by mini-css-extract-plugin
export default {"root":"AppSideNavLocal__root--ldThHsz","isResizing":"AppSideNavLocal__isResizing--ruzz6Ft","rootResizeHandler":"AppSideNavLocal__rootResizeHandler--S4FUUMR","isCollapsed":"AppSideNavLocal__isCollapsed--wnyKD6Z","firstMenu":"AppSideNavLocal__firstMenu--KZuyjS8","content":"AppSideNavLocal__content--ASlcX3x","textWithTooltip":"AppSideNavLocal__textWithTooltip--Gdc_Hrg","title":"AppSideNavLocal__title--bg1OqJY","rightButtons":"AppSideNavLocal__rightButtons--yafPC5F","closeChevronDown":"AppSideNavLocal__closeChevronDown--K1iegfJ","summaryContainer":"AppSideNavLocal__summaryContainer--m6u8vNB","tmpMenuSetOptimizeSummary":"AppSideNavLocal__tmpMenuSetOptimizeSummary--VUbHqWN","link":"AppSideNavLocal__link--UNcjc0D","secondRoot":"AppSideNavLocal__secondRoot--TaRhiHT","secondMenu":"AppSideNavLocal__secondMenu--LBP6_hE","divider":"AppSideNavLocal__divider--cbxH6vU","thirdRoot":"AppSideNavLocal__thirdRoot--lmjAnO5","chevron":"AppSideNavLocal__chevron--qGMxRCp"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"root\":\"AppSideNavLocal__root--ldThHsz\",\"isResizing\":\"AppSideNavLocal__isResizing--ruzz6Ft\",\"rootResizeHandler\":\"AppSideNavLocal__rootResizeHandler--S4FUUMR\",\"isCollapsed\":\"AppSideNavLocal__isCollapsed--wnyKD6Z\",\"firstMenu\":\"AppSideNavLocal__firstMenu--KZuyjS8\",\"content\":\"AppSideNavLocal__content--ASlcX3x\",\"textWithTooltip\":\"AppSideNavLocal__textWithTooltip--Gdc_Hrg\",\"title\":\"AppSideNavLocal__title--bg1OqJY\",\"rightButtons\":\"AppSideNavLocal__rightButtons--yafPC5F\",\"closeChevronDown\":\"AppSideNavLocal__closeChevronDown--K1iegfJ\",\"summaryContainer\":\"AppSideNavLocal__summaryContainer--m6u8vNB\",\"tmpMenuSetOptimizeSummary\":\"AppSideNavLocal__tmpMenuSetOptimizeSummary--VUbHqWN\",\"link\":\"AppSideNavLocal__link--UNcjc0D\",\"secondRoot\":\"AppSideNavLocal__secondRoot--TaRhiHT\",\"secondMenu\":\"AppSideNavLocal__secondMenu--LBP6_hE\",\"divider\":\"AppSideNavLocal__divider--cbxH6vU\",\"thirdRoot\":\"AppSideNavLocal__thirdRoot--lmjAnO5\",\"chevron\":\"AppSideNavLocal__chevron--qGMxRCp\"}";
        // 1727789557943
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  