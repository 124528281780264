// extracted by mini-css-extract-plugin
export default {"container":"LoginView__container--DgSGEgY","contentBox":"LoginView__contentBox--cHKJJYe","logo":"LoginView__logo--UuLkrzq"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"LoginView__container--DgSGEgY\",\"contentBox\":\"LoginView__contentBox--cHKJJYe\",\"logo\":\"LoginView__logo--UuLkrzq\"}";
        // 1723129360160
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  