// extracted by mini-css-extract-plugin
export default {"container":"OrganizationSwitchDialog__container--HucZQla","organizationLink":"OrganizationSwitchDialog__organizationLink--wsm_en4","organizationDisableLink":"OrganizationSwitchDialog__organizationDisableLink--_LGzbA7"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"OrganizationSwitchDialog__container--HucZQla\",\"organizationLink\":\"OrganizationSwitchDialog__organizationLink--wsm_en4\",\"organizationDisableLink\":\"OrganizationSwitchDialog__organizationDisableLink--_LGzbA7\"}";
        // 1723129361261
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  