import { useGetSheet, useSetSheet } from "../domain/Sheet";
import {
  SheetDataRow,
  useGetSheetDataRows,
  useSetSheetDataRow,
} from "../domain/SheetDataRow";
import { useGetSheetFieldSchemas } from "../domain/SheetFieldSchema";
import { ManagedTransaction } from "../firebase/firestore";

import { getSinglyLinkedSheetDataRowList } from "./getSinglyLinkedSheetDataRowList";

import { generateId } from "@/utils/generateId";

/**
 * @description csvをSheetに追加で書き込む
 */
export const csvToSheetAppend = async ({
  sheetId,
  setSheet,
  csvSheetDataRows,
  setSheetDataRow,
  getSheet,
  getSheetDataRows,
  getSheetFieldSchemas,
}: {
  sheetId: string;
  csvSheetDataRows: any[];
  setSheet: ReturnType<typeof useSetSheet>;
  setSheetDataRow: ReturnType<typeof useSetSheetDataRow>;
  getSheet: ReturnType<typeof useGetSheet>;
  getSheetDataRows: ReturnType<typeof useGetSheetDataRows>;
  getSheetFieldSchemas: ReturnType<typeof useGetSheetFieldSchemas>;
}) => {
  if (csvSheetDataRows.length === 0) throw new Error(`CSVデータがありません`);
  const sheet = await getSheet(sheetId, {});
  const sheetDataRows = await getSheetDataRows({
    sheetId,
  });
  const sheetFieldSchemas = await getSheetFieldSchemas({
    sheetId,
  });
  if (sheetFieldSchemas.length === 0 || !sheet)
    throw new Error(`必要なシートデータがありません`);

  // 全ての行で必要なキーが揃っているかを確認する
  // NOTE: CSVになっているので、一行調べれば十分としている
  const csvHeaders = Object.keys(csvSheetDataRows[0]);
  const lackHeaders: string[] = [];
  // スキーマを構成するキーを揃えているか
  console.log("[csvHeaders]", csvHeaders);
  const isHeaderOk = sheetFieldSchemas
    .map(({ displayName }) => {
      const isExsitHeader = csvHeaders.includes(displayName);
      console.log(displayName, isExsitHeader);
      !isExsitHeader && lackHeaders.push(displayName);
      return isExsitHeader;
    })
    .every((tf) => tf);
  // const isHeaderOk = csvSheetDataRows.every((row) => {
  //   const keys = Object.keys(row);
  //   return sheetFieldSchemas.every(({ displayName }) => {
  //     return keys.includes(displayName);
  //   });
  // });
  if (!isHeaderOk)
    throw new Error(`次のスキーマが不足しています [${lackHeaders.join(", ")}]`);
  // 確認を終えたので、キーと型を変換
  const sheetDataCells: SheetDataRow["sheetDataCells"][] = [];
  const map = new Map<string, string>();
  sheetFieldSchemas.forEach(({ id, displayName }) => {
    map.set(displayName, id);
  });
  csvSheetDataRows.map((row) => {
    const obj: SheetDataRow["sheetDataCells"] = {};
    for (const [displayName, value] of Object.entries(row)) {
      const id = map.get(displayName);
      if (!id) continue; // 存在しない場合は飛ばす
      const text = value as string;
      obj[id] = text;
    }
    sheetDataCells.push(obj);
  });

  // 元々の最終行を取得
  const {
    rows: singlyLinkedListRows,
    lastRowId,
    firstRowId,
  } = getSinglyLinkedSheetDataRowList(sheet, sheetDataRows);

  // データを書き込む
  const rowIds = Array.from({ length: sheetDataCells.length }, () =>
    generateId()
  );
  await ManagedTransaction.runTransaction(async (transaction) => {
    console.log("AAAAA");
    for (const [index, row] of sheetDataCells.entries()) {
      await setSheetDataRow(
        {
          sheetDataCells: row,
          id: rowIds[index],
          nextRowId: index < rowIds.length - 1 ? rowIds[index + 1] : null,
          isRequired: false,
        },
        { sheetId },
        { transaction }
      );
    }
  });

  if (lastRowId) {
    console.log("WWWW");
    await setSheetDataRow(
      { ...singlyLinkedListRows.slice(-1)[0], nextRowId: rowIds[0] },
      { sheetId },
      {}
    );
  }
  if (!firstRowId) {
    console.log("RRRR");
    await setSheet({ ...sheet, firstRowId: rowIds[0] }, {}, {});
  }
  console.log("DDDD");
};
