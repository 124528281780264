// extracted by mini-css-extract-plugin
export default {"notifications":"AppNotifications__notifications--QnrLKAG","notificationContainer":"AppNotifications__notificationContainer--Ex1qts3","showNotification":"AppNotifications__showNotification--TMmlRDI","disposed":"AppNotifications__disposed--gDsdzm0","hideNotification":"AppNotifications__hideNotification--MUSUJK3","notification":"AppNotifications__notification--_sGr7jF","messageBar":"AppNotifications__messageBar--BPDqJ7D"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"notifications\":\"AppNotifications__notifications--QnrLKAG\",\"notificationContainer\":\"AppNotifications__notificationContainer--Ex1qts3\",\"showNotification\":\"AppNotifications__showNotification--TMmlRDI\",\"disposed\":\"AppNotifications__disposed--gDsdzm0\",\"hideNotification\":\"AppNotifications__hideNotification--MUSUJK3\",\"notification\":\"AppNotifications__notification--_sGr7jF\",\"messageBar\":\"AppNotifications__messageBar--BPDqJ7D\"}";
        // 1723129359956
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  