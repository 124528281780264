// extracted by mini-css-extract-plugin
export default {"container":"ErrorWrapper__container--vbJlag3","errorIcon":"ErrorWrapper__errorIcon--NILYTNP","buttonContainer":"ErrorWrapper__buttonContainer--jn75G5o","errorInfoContainer":"ErrorWrapper__errorInfoContainer--__BZHao","errorInfoHeading":"ErrorWrapper__errorInfoHeading--ORDccRy","errorInfoDescription":"ErrorWrapper__errorInfoDescription--S6eXi2E","missingPermissionsList":"ErrorWrapper__missingPermissionsList--rjgLb89","details":"ErrorWrapper__details--bMIJLz7","errorSummary":"ErrorWrapper__errorSummary--P0x6fPI","errorDetails":"ErrorWrapper__errorDetails--rG0Inqr","errorDisplayWrapper":"ErrorWrapper__errorDisplayWrapper--N7TqDIh","buttons":"ErrorWrapper__buttons--_KsmdWM"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"ErrorWrapper__container--vbJlag3\",\"errorIcon\":\"ErrorWrapper__errorIcon--NILYTNP\",\"buttonContainer\":\"ErrorWrapper__buttonContainer--jn75G5o\",\"errorInfoContainer\":\"ErrorWrapper__errorInfoContainer--__BZHao\",\"errorInfoHeading\":\"ErrorWrapper__errorInfoHeading--ORDccRy\",\"errorInfoDescription\":\"ErrorWrapper__errorInfoDescription--S6eXi2E\",\"missingPermissionsList\":\"ErrorWrapper__missingPermissionsList--rjgLb89\",\"details\":\"ErrorWrapper__details--bMIJLz7\",\"errorSummary\":\"ErrorWrapper__errorSummary--P0x6fPI\",\"errorDetails\":\"ErrorWrapper__errorDetails--rG0Inqr\",\"errorDisplayWrapper\":\"ErrorWrapper__errorDisplayWrapper--N7TqDIh\",\"buttons\":\"ErrorWrapper__buttons--_KsmdWM\"}";
        // 1723129358552
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  